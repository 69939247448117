<template>
  <div class="mydocument">
    <div
      style="
        max-width: 600px;
        width: 100%;
        background: #fff;
        border-radius: 8px;
        margin: 30px auto;
        padding: 10px;
      "
    >
      <span
        style="
          color: crimson;
          font-weight: 700;
          font-size: 16px;
          text-align: center;
          margin-bottom: 20px;
        "
        v-if="error"
      >
        {{ message }}
      </span>
      <span
        style="
          color: green;
          font-weight: 700;
          font-size: 16px;
          text-align: center;
          margin-bottom: 20px;
        "
        v-if="error == false"
      >
        {{ message }}
      </span>
      <span
        style="
          margin-bottom: 30px;
          margin-top: 20px;
          color: blue;
          cursor: pointer;
          font-weight: 600;
        "
        @click="goHome"
        >GO HOME</span
      >
      <div style="display: flex; flex-direction: column; gap: 10px">
        <div style="display: flex; justify-content: space-between; align-items: center">
          <h3>BVN Documnets</h3>
        </div>
        <div style="display: flex; flex-direction: column; gap: 4px">
          <span>ID TYPE</span>
          <h4>{{ userData.idtype }}</h4>
        </div>

        <div style="display: flex; flex-direction: column; gap: 4px">
          <span>BVN Status</span>
          <h4>
            {{
              userData.bvnstatus == "true"
                ? "Approved"
                : userData.bvnstatus == "false"
                ? "Rejected"
                : "Pending"
            }}
          </h4>
        </div>
        <div style="display: flex; flex-direction: column; gap: 4px">
          <strong>ID DOCUMENTS</strong>
          <img
            :src="`https://api.tapit.ng/public/storage/images/idimage/${userData.id}.jpg`"
            alt=""
            style="width: 100%; min-height: 300px"
          />
        </div>
        <div style="display: flex; flex-direction: column; gap: 4px">
          <h4>Personal Selfie</h4>
          <img
            :src="`https://api.tapit.ng/public/storage/images/selfie/${userData.id}.jpg`"
            alt=""
            style="height: 300px; width: 100%"
          />
        </div>
        <div
          class="ech-detail"
          style="margin-top: 30px; display: flex; gap: 10px"
          v-if="userData.mbvn == 1"
        >
          <button class="btn-direct" @click="manualverifybvn(1, 'a')">
            {{ loadera == true ? "Loading" : "Verify BVN" }}
          </button>
          <button
            class="btn-direct"
            @click="manualverifybvn(2, 'r')"
            style="color: #fff; background: crimson"
          >
            {{ loaderr == true ? "Loading" : "Reject BVN" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ViewDocument",

  data() {
    return {
      userData: [],
      token: "",
      loadera: false,
      loaderr: false,
      error: null,
      message: "",
      data: "",
      id: "",
    };
  },
  methods: {
    goHome() {
      this.$router.push("/admin/dashboard");
    },
    async manualverifybvn(state, type) {
      const data = JSON.parse(localStorage.getItem("admin"));
      this.token = data.token;
      if (type === "a") {
        this.loadera = true;
      } else {
        this.loaderr = true;
      }
      const datas = {
        id: this.userData.id,
        accept: state,
      };
      await axios
        .post(`${process.env.VUE_APP_BASE_URL}api/manualverifybvn`, datas, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          //  console.log(res);
          const getUsers = axios.get(
            `${process.env.VUE_APP_BASE_URL}api/finduserbyusername?username=${this.$route.params.username}`,
            {
              headers: {
                Authorization: "Bearer " + this.token,
              },
            }
          );
          this.userData = getUsers.data.data;
          this.loadera = false;
          this.loaderr = false;
          this.error = false;
          this.message = res.data.message;
          setTimeout(() => {
            this.message = "";
          }, 3000);
        })
        .catch((e) => {
          console.log(e);
          this.loadera = false;
          this.loaderr = false;
          this.error = true;
          this.message = "Error Verifying BVN";
          setTimeout(() => {
            this.message = "";
          }, 3000);
        });
    },
  },
  async mounted() {
    this.hidedetails = !this.hidedetails;
    const data = JSON.parse(localStorage.getItem("admin"));

    this.token = data.token;

    try {
      const getUsers = await axios.get(
        `${process.env.VUE_APP_BASE_URL}api/finduserbyusername?username=${this.$route.params.username}`,
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      );
      this.userData = getUsers.data.data;
      //   console.log(getUsers);
    } catch (e) {
      if (e.response.status === 401) {
        this.$router.push("/");
        localStorage.removeItem("admin");
      }
    }
  },
};
</script>
<style>
.mydocument {
  height: 100%;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-direct {
  background: #0a1aa8;
  color: #fff;
  border: 1px solid #0a1aa8;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}
</style>
